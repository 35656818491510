import { Link } from "react-router-dom";
import Logo4 from "../../assets/images/logo4.png";
import { THEME_CONFIG } from "../../Constant/constant";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div
                className="single-footer-widget"
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="logo">
                  <Link to="/">
                    <img src={Logo4} alt="Logo" />
                  </Link>
                </div>

                <p>
                At Sipheus, we redefine IT excellence with innovative solutions, exceeding client needs. From technology to service, we're committed to setting you apart. Explore possibilities with Sipheus Infotech.
                </p>

                {/* <ul className="social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://linkedin.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="single-footer-widget ml-4 pl-5"
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <h3>Explore</h3>

                <ul className="list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about/">About</Link>
                  </li>
                  <li>
                    <Link to="/services/">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact/">Contact Us</Link>
                  </li>
                  {THEME_CONFIG.FOOTER.IS_SHOW_LINK && (
                    <>
                      <li>
                        <Link to="/portfolio/">Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/team/">Team</Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

           

            <div className="col-lg-3 col-sm-6">
              <div
                className="single-footer-widget"
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-delay="400"
              >
                <h3>Get in Touch</h3>

                <ul className="get-in-touch">
                  {/* <li>
                    <i className="fa-solid fa-location-dot"></i> 2750 Quadra
                    Street Victoria, Canada.
                  </li> */}
                  <li>
                    <i className="fa-solid fa-headset"></i>
                    <a href="tel:+919950545368">+91-9950545368</a>
                   
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:support@sipheusinfotech.com">support@sipheusinfotech.com</a>
                   
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <p>
            
              Copyright &copy; {currentYear} Sipheus Infotech. All Rights Reserved{" "}
              <a href="https://envytheme.com" target="_blank" rel="noreferrer">
                
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
