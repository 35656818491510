import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      header: require('./en/header.json'),
      home: require('./en/home.json'),
    },
    de: {
      header: require('./de/header.json'),
      home: require('./de/home.json'),
    },
  },
  ns: ['header'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'de'];

export default i18n;
