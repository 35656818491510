import { useTranslation } from 'react-i18next';
import AboutImg from "../../assets/images/about-img3.jpg"

const WhyChooseUs = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div
                className="about-content about-content-two pl-0 mt-0"
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="section-title">
                  <h2>{t('home:OURSERVICES.whychoose')}</h2>
                  <p>
                    {t('home:OURSERVICES.westandout')}
                  </p>
                </div>

                <div className="about-text">
                  <h4>{t('home:OURSERVICES.foundedin')}</h4>
                  <p>
                    {t('home:OURSERVICES.foundedinthis')}
                  </p>
                </div>

                <div className="about-text">
                  <h4>{t('home:OURSERVICES.ourhistory')}</h4>
                  <p>
                    {t('home:OURSERVICES.withthegoal')}
                  </p>
                </div>

                <div className="about-text">
                  <h4>{t('home:OURSERVICES.inmission')}</h4>
                  <p>
                    {t('home:OURSERVICES.issimple')}
                  </p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12">
              <div
                className="about-image right-image"
                data-aos="fade-in"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img
                  src={AboutImg}
                  alt="about"
                  className="rounded-10"
                />

                {/* <div className="video-box">
                  <div
                    onClick={() => setToggler(!toggler)}
                    className="video-btn"
                  >
                    <i className="fa-solid fa-play"></i>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs;
