import { useState } from "react";
import FsLightbox from "fslightbox-react";

import AboutImg from "../../assets/images/about-img2.jpg"

const AboutContent = () => {
  const [toggler,] = useState(false);
  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/bk7McNUjWgw"]}
      />

      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="about-image">
                <img
                  src={AboutImg}
                  alt="About"
                  className="rounded-10"
                />

                {/* <div className="video-box">
                  <div
                    className="video-btn"
                    onClick={() => setToggler(!toggler)}
                  >
                    <i className="fa-solid fa-play"></i>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="about-content about-content-two">
                <div className="section-title">
                  <h2>About Us</h2>
                  <p>
                    At Sipheus, we stand out as your premier choice for IT services because we are more than just service providers; we're your dedicated technology partners. With a relentless commitment to innovation, we tailor our solutions to your unique business needs, ensuring that every service we offer is a step towards your success.
                  </p>
                </div>

                <div className="about-text">
                  <h4>Who We Are</h4>
                  <p>
                    Sipheus Infotech, founded in 2018, has evolved into a renowned IT solutions provider that has been instrumental in empowering businesses through technology and innovation. Over the years, we have had the privilege of serving numerous clients, enabling them to navigate the dynamic digital landscape successfully. Our journey from inception to becoming a trusted name in the industry has been characterized by a relentless commitment to excellence.
                  </p>
                </div>

                <div className="about-text">
                  <h4>Our History</h4>
                  <p>
                    Sipheus Infotech was founded in 2018 with the goal of delivering client-focused solutions that utilize the most recent technological developments. We have dedicated ourselves to comprehending the distinct requirements and obstacles of our consumers since the beginning, developing customized solutions that produce noticeable outcomes. We have consistently embraced innovation, staying ahead of industry trends, and adjusting to the changing tech landscape. Sipheus Infotech is proud to be recognized as one of the best IT service providers in India. Our reputation has been built on a foundation of trust, reliability, and a commitment to ethical values.
                  </p>
                </div>

                <div className="about-text">
                  <h4>Our Mission</h4>
                  <ul>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Empower Businesses: Drive success by providing innovative solutions that enhance efficiency and foster growth.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Client-Centric Approach: Tailor services to exceed client expectations, putting their needs at the forefront.
                    </li>
                    <li>
                      Strive for Excellence: Set and maintain high standards in all endeavors to deliver top-notch services.
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Embrace Innovation: Foster a culture of continuous improvement, embracing new technologies and staying ahead of the curve.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Build Long-Term Partnerships: Cultivate lasting relationships with clients, grounded in trust, reliability, and shared success.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Make a Positive Impact: Extend our mission beyond business success to contribute positively to the communities we serve.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Commit to Continuous Learning: Promote ongoing knowledge enhancement for both our team and clients.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Ethical and Transparent Practices: Uphold the highest standards of integrity and transparency in all business operations.

                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Strategic Problem-Solving: Approach challenges strategically, providing effective, tailor-made solutions.


                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>
                      Create a Positive Work Environment: Cultivate a collaborative, positive, and inclusive workplace for our team.


                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
