import React from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const servicesData = [
  {
    iconName: "pe-7s-comment bg-13c4a1",
    title: "Business Consultancy",
    shortText:
      "Sipheus Infotech: Elevate your business with expert IT consultancy. Tailored solutions for software, cloud, cybersecurity, and infrastructure. Stay ahead in the digital era, make informed decisions, cut costs, and drive innovation. Choose us for a transformative experience.",
    viewDetails: "/services",
    aosDelay: "100",
  },
  {
    iconName: "pe-7s-display2 bg-6610f2",
    title: "Web Development",
    shortText:
      "Transform your online presence with Sipheus Infotech's Web Development services. Our expert team crafts responsive, feature-rich websites aligned with your corporate goals. Whether starting from scratch or updating an existing site, we prioritize user-friendly design, optimal performance, and cutting-edge technology. Join us for a web development adventure that captivates visitors, encourages interaction, and fuels your online success. ",
    viewDetails: "/services",
    aosDelay: "200",
  },
  {
    iconName: "pe-7s-light bg-ffb700",
    title: "Digital Marketing",
    shortText:
      "Unlock online potential with Sipheus Infotech's Digital Marketing services. Our expert team navigates SEO, social media, PPC, and more, creating tailored strategies for brand visibility and measurable results. With a focus on data-driven decisions, choose us for a digital marketing journey that maximizes online reach and ensures a strong ROI.",
    viewDetails: "/services",
    aosDelay: "300",
  },
  {
    iconName: "pe-7s-phone bg-fc3549",
    title: "Mobile App Development",
    shortText:
      "Realize your app concepts with Sipheus Infotech's Mobile App Development. Our dedicated developers create unique, user-friendly apps tailored to your business needs. Whether enhancing an existing app or introducing a new one, we focus on high performance and engagement. Stay ahead with cutting-edge mobile technologies. Trust Sipheus Infotech to turn your ideas into user-focused mobile applications.",
    viewDetails: "/services",
    aosDelay: "400",
  },
  {
    iconName: "pe-7s-cart bg-00d280",
    title: "eCommerce Development",
    shortText:
      "Elevate your online store with Sipheus Infotech's eCommerce Development. Our expert team crafts custom, feature-rich web stores tailored to your needs. Whether launching or enhancing, we prioritize user-friendly, high-performance solutions, driving sales and standing out. Trust Sipheus Infotech for an eCommerce experience that enhances your storefront, attracts clients, and fuels online growth.",
    viewDetails: "/services",
    aosDelay: "500",
  },
  {
    iconName: "pe-7s-users bg-ff612f",
    title: "Marketing & Reporting",
    shortText:
      "Unlock growth with Sipheus! Our expert team provides dynamic marketing and reporting services, serving as your strategic manual. Whether boosting sales, elevating online visibility, or monitoring performance, we tailor solutions to your needs. Take action with Sipheus – where innovation meets experience, setting your company apart.",
    viewDetails: "/services",
    aosDelay: "600",
  },
  {
    iconName: "pe-7s-headphones bg-6610f2",
    title: "IT Consultancy",
    shortText:
      "Sipheus Infotech, your trusted partner in IT consultancy, delivers innovative solutions tailored to elevate your business. Our expert team navigates the digital landscape, offering strategic insights and cutting-edge technologies for seamless growth and success.",
    viewDetails: "/services",
    aosDelay: "700",
  },
  {
    iconName: "pe-7s-users bg-13c4a1",
    title: "Social Marketing",
    shortText:
      "Sipheus Infotech excels in Social Marketing, crafting impactful strategies that amplify your brand's presence. Our team harnesses the power of social media to engage your audience, build meaningful connections, and drive results. Elevate your digital presence with our expertise in social marketing solutions.",
    viewDetails: "/services",
    aosDelay: "800",
  },
  {
    iconName: "pe-7s-comment bg-fc3549",
    title: "Admin Support",
    shortText:
      "Sipheus Infotech provides top-notch Admin Support services, ensuring streamlined operations for your business. Our dedicated team offers efficient solutions, handling administrative tasks with precision and expertise. Trust us to optimize your workflow and enhance organizational efficiency through reliable admin support.",
    viewDetails: "/services",
    aosDelay: "900",
  },
];

const ServicesStyleThree = () => {
    const { t } = useTranslation(['home']);
  return (
    <>
      <div className="pt-100 pb-70">
        <div className="container">
        <div className="section-title">
            <h2>{t('home:OURSERVICES.ourservices')}</h2>
            <p>
            {t('home:OURSERVICES.powerofour')}
            </p>
          </div>
          <div className="row justify-content-center">
            {servicesData &&
              servicesData.map((value, i) => (
                <div
                  className="col-lg-4 col-sm-6"
                  key={i}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay={value.aosDelay}
                >
                  <div className="service-card-one bg-fcfbfb">
                    <i className={value.iconName}></i>
                    <h3>
                      <Link to={value.viewDetails}>{value.title}</Link>
                    </h3>
                    <p>{value.shortText}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesStyleThree;
