import { Link } from "react-router-dom";
import { funFactsData } from "../../Constant/FunFactsData/FunFacts";
import Map from '../../assets/images/map.png'
import { useTranslation } from 'react-i18next';
const FunFactsTwo = () => {
  const { t } = useTranslation(['home']);
  return (
    <>
      <div className="funfacts-area ptb-100 bg-fcfbfb">
        <div className="container">
          <div className="section-title">
            <h2>{t('home:OURSERVICES.consistently')}</h2>
            <p>
            {t('home:OURSERVICES.empoweryour')}
            </p>
          </div>

          <div className="row justify-content-center">
            {funFactsData &&
              funFactsData.slice(0, 4).map((value, i) => (
                <div className="col-lg-3 col-sm-6" key={i}>
                  <div
                    className="funfact"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={value.aosDelay}
                  >
                    <i className={value.iconName}></i>
                    <h3>
                      {value.number}
                    </h3>
                    <p>{value.shortText}</p>
                  </div>
                </div>
              ))}
          </div>

          <div
            className="contact-cta-box"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <h3>Have any questions about us?</h3>
            <p>Don't hesitate to contact us</p>

            <Link to="/contact" className="btn btn-primary">
              Contact Us
            </Link>
          </div>

          <div className="map-bg">
            <img src={Map} alt="map" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FunFactsTwo;
