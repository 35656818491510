import PageBanner from "../Components/Common/PageBanner";
import ContactInfo from "../Components/Contact/ContactInfo";
import ContactForm from "../Components/Contact/ContactForm";
import Newsletter from "../Components/Common/Newsletter";

import PageBannerImg from "../assets/images/page-banner1.jpg"

const Contact = () => {
  return (
    <>

      <PageBanner pageTitle="Contact Us" BGImage={PageBannerImg} />

      <ContactInfo />

      <ContactForm />

      <div className="ptb-100">
        <Newsletter />
      </div>

    </>
  );
};

export default Contact;
