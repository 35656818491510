import React from 'react';

//import Components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


const Layout = (props: any) => {
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};


export default Layout;