import { Navigate } from "react-router-dom";

//Dashboard
import Home from "../Pages/Home";
import Services from "../Pages/Services";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Blog from "../Pages/Blog";

const RoutesData = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/services", component: <Services /> },
  { path: "/about", component: <About /> },
  { path: "/contact", component: <Contact /> },
  { path: "/blog", component: <Blog /> },
  { path: "*", component: <Navigate to="/home" /> },
];

export { RoutesData };