import Blog7 from "../../assets/images/blog/blog7.jpg"
import Blog8 from "../../assets/images/blog/blog8.jpg"
import Blog9 from "../../assets/images/blog/blog9.jpg"
import Blog10 from "../../assets/images/blog/blog10.jpg"

export const latestNewsData = [
  {
    image: Blog7,
    title: "The Most Popular New top Business Apps",
    date: "Feb 15, 2023",
    category: "Technology",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
  },
  {
    image:  Blog8,
    title: "The Best Marketing top use Management Tools",
    date: "Feb 16, 2023",
    category: "Agency",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
  },
  {
    image: Blog9,
    title: "3 WooCommerce Plugins to Boost Sales",
    date: "Feb 17, 2023",
    category: "IT Agency",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
  },
  {
    image: Blog10,
    title: "Top 21 Must-Read Blogs For Creative Agencies",
    date: "Feb 18, 2023",
    category: "Development",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
  },
];