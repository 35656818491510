import BlogImg1 from "../../assets/images/blog/blog1.jpg"
import BlogImg2 from "../../assets/images/blog/blog2.jpg"
import BlogImg3 from "../../assets/images/blog/blog3.jpg"
import BlogImg4 from "../../assets/images/blog/blog4.jpg"
import BlogImg5 from "../../assets/images/blog/blog5.jpg"
import BlogImg6 from "../../assets/images/blog/blog6.jpg"

export const BlogPostData = [
  {
    image: BlogImg1,
    title: "The Most Popular New top Business Apps",
    date: "Feb 15, 2023",
    category: "Technology",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "100",
  },
  {
    image: BlogImg2,
    title: "The Best Marketing top use Management Tools",
    date: "Feb 16, 2023",
    category: "Agency",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "200",
  },
  {
    image: BlogImg3,
    title: "3 WooCommerce Plugins to Boost Sales",
    date: "Feb 17, 2023",
    category: "IT Agency",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "300",
  },
  {
    image: BlogImg4,
    title: "Top 21 Must-Read Blogs For Creative Agencies",
    date: "Feb 18, 2023",
    category: "Development",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "400",
  },
  {
    image: BlogImg5,
    title: "The Most Popular New top Business Apps",
    date: "Feb 19, 2023",
    category: "Apps",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "500",
  },
  {
    image: BlogImg6,
    title: "The Best Marketing top use Management Tools",
    date: "Feb 20, 2023",
    category: "Marketing",
    shortText:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete",
    readMoreLink: "/blog-details",
    aosDelay: "600",
  },
];
