import "./LanguageTrans.css"
import { useEffect, useState } from 'react';
// import Select from "react-select";
import { LanguageData } from '../../Common/types';
import { useTranslation } from "react-i18next";
// import UsFlag from '../../../assets/images/langFlag/united-states-flag-icon.svg'
// import GermanFlag from '../../../assets/images/langFlag/germany-flag-icon.svg'

const LanguageTrans = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(LanguageData[0]);
  // const [flag, setFlag] = useState(GermanFlag);
  const { i18n } = useTranslation();

  // const handleLanguageChange = (selectedValue: any) => {
  //   i18n.changeLanguage(selectedValue.value);
  //   setSelectedLanguage(selectedValue);
  // }

  useEffect(() => {
    let lang = localStorage.getItem('language')
    if (lang) {
      i18n.changeLanguage(lang);
      if (lang === LanguageData[0].value) {
        // setFlag(GermanFlag)
        setSelectedLanguage(LanguageData[0])
      }
      else {
        // setFlag(UsFlag)
        setSelectedLanguage(LanguageData[1])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLanguageChange = () => {
    if (selectedLanguage.value === LanguageData[0].value) {
      i18n.changeLanguage(LanguageData[1].value);
      setSelectedLanguage(LanguageData[1]);
      // setFlag(UsFlag)
      localStorage.setItem('language', LanguageData[1].value)
    }
    else {
      i18n.changeLanguage(LanguageData[0].value);
      setSelectedLanguage(LanguageData[0]);
      // setFlag(GermanFlag)
      localStorage.setItem('language', LanguageData[0].value)
    }
  }
  return (
    <div className="floating-button-div">
      {/* <Select
        className='fb'
        value={selectedLanguage}
        onChange={handleLanguageChange}
        options={LanguageData}
        name="Language"
        placeholder="Select Language"
      /> */}
      <div className='lang' onClick={handleLanguageChange}>
        {/* <img className="flag" src={flag} alt="flag" /> */}
        {selectedLanguage.label}
      </div>
    </div>
  )
}

export default LanguageTrans;