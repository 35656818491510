import MainBanner from "../Components/MainBanner/MainBanner";
import WhyChooseUs from "../Components/WhyChooseUs/WhyChooseUs";
import FunFactsTwo from "../Components/FunFacts/FunFactsTwo";
import Feedback from "../Components/Feedback/Feedback";
import LatestNewsSlider from "../Components/LatestNewsSlider/LatestNewsSlider";
import Newsletter from "../Components/NewsLetter/NewsLetter";
import { THEME_CONFIG } from "../Constant/constant";
import ServicesStyleThree from "../Components/ServiceStyleThree";

const Home = () => {
  return (
    <>
      <MainBanner />
      <ServicesStyleThree />
      <WhyChooseUs />
      <FunFactsTwo />
      {THEME_CONFIG.COMPONENT.IS_SHOW_COMPONENT && (
        <>
          <Feedback />
          <LatestNewsSlider />
        </>
      )}

      <div className="pb-100">
        <Newsletter />
      </div>
    </>
  );
};

export default Home;
