import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//Layouts
import Layout from "../Components/Layouts/index";

//routes
import { RoutesData } from "./allRoutes";
import LanguageTrans from "../Components/LanguageTrans/LanguageTrans";
import { THEME_CONFIG } from "../Constant/constant";

const Index = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route>
            {RoutesData.map((route, idx) => (
              <Route
                path={route.path}
                element={<Layout>{route.component}</Layout>}
                key={idx}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
      {THEME_CONFIG.COMPONENT.IS_SHOW_COMPONENT && <LanguageTrans />}
    </React.Fragment>
  );
};

export default Index;
