import './App.css';
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import Route from './Routes/index'
import './assets/styles/index.css'
import "swiper/css";
import "swiper/css/bundle";
import "animate.css";
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <Route />
  );
}

export default App;
