import PageBanner from "../Components/Common/PageBanner";
import AboutContent from "../Components/AboutContent/AboutContent";
import FunFactsTwo from "../Components/FunFacts/FunFactsTwo";
import Feedback from "../Components/Feedback/Feedback";
import CtaAreaTwo from "../Components/Common/CtaAreaTwo";
import AboutImg from "../assets/images/page-banner1.jpg"
import { THEME_CONFIG } from "../Constant/constant";
import WhyChooseUs from "../Components/WhyChooseUs/WhyChooseUs";

const About = () => {
  return (
    <>
      <PageBanner pageTitle="About Us" BGImage={AboutImg} />
      <AboutContent />
      <WhyChooseUs />
      <FunFactsTwo />
      {THEME_CONFIG.COMPONENT.IS_SHOW_COMPONENT && (
        <Feedback />
      )}
      <CtaAreaTwo />

    </>
  );
};

export default About;
