import React from "react";
import PageBanner from "../Components/Common/PageBanner";
import BlogGridThree from "../Components/Blog/Blog";

import PageBannerImg from "../assets/images/page-banner3.jpg"

const Blog = () => {
  return (
    <>

      <PageBanner pageTitle="Blog Grid" BGImage={PageBannerImg} />

      <BlogGridThree />

    </>
  );
};

export default Blog;
