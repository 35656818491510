import React from "react";
import PageBanner from "../Components/Common/PageBanner";

import CtaAreaTwo from "../Components/Common/CtaAreaTwo";

import PageBannerImg from "../assets/images/page-banner1.jpg"
import ServicesStyleThree from "../Components/ServiceStyleThree";

const Services = () => {
  return (
    <>
      <PageBanner pageTitle="Our Services" BGImage={PageBannerImg} />

      <ServicesStyleThree />

      <CtaAreaTwo />
    </>
  );
};

export default Services;
