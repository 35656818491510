export const funFactsData = [
  {
    iconName: "fa-solid fa-circle-check",
    number: "57+",
    shortText: "Completed Projects",
    aosDelay: "100",
  },
  {
    iconName: "fa-solid fa-heart",
    number: "100+",
    shortText: "Happy Clients",
    aosDelay: "200",
  },
  {
    iconName: "fa-solid fa-list-check",
    number: "50+",
    shortText: "Ongoing Projects",
    aosDelay: "300",
  },
  {
    iconName: "fa-solid fa-trophy",
    number: "70+",
    shortText: "Winning Awards",
    aosDelay: "400",
  },
];