import React, { useState, useEffect } from "react";
import Logo from '../../assets/images/logo4.png'
import { useTranslation } from 'react-i18next';
import LogoWhite from '../../assets/images/logo5.png'
import { Link, useLocation } from "react-router-dom";
import { THEME_CONFIG } from "../../Constant/constant";

const NavbarTwo = () => {
  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();
  const { t } = useTranslation(['header']);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [menu, setMenu] = React.useState(true);
  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (elementId) {
        if (window.scrollY > 170) {
          elementId.classList.add("is-sticky");
        } else {
          elementId.classList.remove("is-sticky");
        }
      }
    });
  });

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";


    const handleClick=()=>{
      window.scroll(0,0)
    }
  return (
    <>
      <div id="navbar" className="navbar-area navbar-style-2">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={Logo} className="black-logo" alt="logo" />
              <img
                src={LogoWhite}
                className="white-logo"
                alt="logo"
              />
            </Link>
            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>
            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to="/"
                    onClick={handleClick}
                    className={`nav-link ${currentPath === "/" && "active"}`}
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/about/"
                    onClick={handleClick}
                    className={`nav-link ${currentPath === "/about/" && "active"
                      }`}
                  >
                    About us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/services/"
                    onClick={handleClick}
                    className={`nav-link ${currentPath === "/services/" && "active"
                      }`}
                  >
                    {t('header.service')}
                  </Link>
                </li>
            {
              THEME_CONFIG.MENU.IS_SHOW_BLOG && (
                <li className="nav-item">
                <Link
                  to="/blog/"
                  className={`nav-link ${currentPath === "/blog/" && "active"
                    }`}
                >
                  Blog
                </Link>
              </li>
              )
            }
               

                <li className="nav-item">
                  <Link
                    to="/contact/"
                    onClick={handleClick}
                    className={`nav-link ${currentPath === "/contact/" && "active"
                      }`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>

              <div className="others-options">
                <Link to="/contact/" className="btn btn-primary">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavbarTwo;
