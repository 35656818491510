export const THEME_CONFIG = {
  MENU: {
    IS_SHOW_BLOG: false,
  },
  HOME: {
    IS_SHOW_BUTTON: false,
  },
  FOOTER: {
    IS_SHOW_LINK: false,
  },
  COMPONENT: {
    IS_SHOW_COMPONENT: false,
  },
};
