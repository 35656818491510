import { useState } from "react";
import FsLightbox from "fslightbox-react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HeroBanner4 from '../../assets/images/hero-banner4.jpg'
import AnimateBanner from '../../assets/images/animate-banner-img1.jpg'
import { THEME_CONFIG } from "../../Constant/constant";

const MainBanner = () => {
  const { t } = useTranslation(['home']);
  const [toggler, setToggler] =  useState(false);
  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/bk7McNUjWgw"]}
      />

      <div
        className="hero-banner it-banner overly"
        style={{
          backgroundImage: (`url(${HeroBanner4})`)
        }}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="main-banner-content">
                    <h1
                      data-aos="fade-in"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      {t('home:OURSERVICES.weare')}
                    </h1>

                    <p
                      data-aos="fade-in"
                      data-aos-duration="1200"
                      data-aos-delay="200"
                    >
                     {t('home:OURSERVICES.innovate')}
                    </p>

                    <Link
                      to="/contact"
                      className="btn btn-primary"
                      data-aos="fade-in"
                      data-aos-duration="1200"
                      data-aos-delay="300"
                    >
                      Get Started
                    </Link>

                   {THEME_CONFIG.HOME.IS_SHOW_BUTTON &&(
                      <button
                        onClick={() => setToggler(!toggler)}
                        className="btn btn-secondary"
                        data-aos="fade-in"
                        data-aos-duration="1200"
                        data-aos-delay="400"
                      >
                        <i className="fa-solid fa-play me-1"></i> How it works
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div
                    className="animate-banner-image"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <img
                      src={AnimateBanner}
                      alt="Animate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainBanner;